/* CSS Document */

.logo {
    background-image: url("data:image/svg+xml, %3Csvg aria-labelledby='title-logo-wbc' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='69px' height='28px' viewBox='0 0 69 28' enable-background='new 0 0 69 28' xml:space='preserve'%3E%3Ctitle id='title-logo-wbc' lang='en'%3EWestpac logo%3C/title%3E%3Cpath d='M24.4 25L17.6 4.1C16.7 0.9 15 0 12.6 0H0c1 0.4 1.6 2.9 1.6 2.9l6.1 21c0.7 2.6 2.9 4 5.4 4h13.3C25.4 27.8 24.4 25 24.4 25' fill='%23DA1710'%3E%3C/path%3E%3Cpath d='M44.6 25l6.8-20.9C52.3 0.9 54 0 56.4 0H69c-1 0.4-1.6 2.9-1.6 2.9l-6.1 21c-0.7 2.6-2.9 4-5.4 4H42.6C43.6 27.8 44.6 25 44.6 25' fill='%23DA1710'%3E%3C/path%3E%3Crect x='27' width='15' height='28' fill='%23DA1710'%3E%3C/rect%3E%3C/svg%3E");
    background-repeat: no-repeat;
    width: 171px;
    height: 55px;
    display: block;
}

.alert {
    border: none;

}

.alert-secondary {
    background-color: rgb(243, 244, 246);

}


.alert-inverted {
    color: $white;
    background-color: $deep-purple;

    p {
        color: inherit;
    }
}