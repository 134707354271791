/*!
 * main.scss
 * Author - Brendan Smith 
 */

// Imports overrides and regular bootstrap
// Omits the need for regular bootstrap.scss
//
@import "./westpactheme";
// Bootstrap next!
@import "../../../node_modules/bootstrap/scss/bootstrap";
// imports particular theme tweaks
@import "./westpacthemeextras";
